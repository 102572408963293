import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Spinner, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDocumentos,
  uploadDocumento,
  deleteDocumento,
} from "../../redux/documentosSlice";

const DocumentosAdminPage = () => {
  const dispatch = useDispatch();
  const { documentos, loading, error } = useSelector((state) => state.documentos);

  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentDetails, setDocumentDetails] = useState({
    nombre: "",
    descripcion: "",
  });
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    dispatch(fetchDocumentos());
  }, [dispatch]);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFile(null);
    setDocumentDetails({ nombre: "", descripcion: "" });
    setUploadError(null);
  };

  const handleShowModal = () => setShowModal(true);

  const handleUploadDocument = async () => {
    setIsUploading(true);
    setUploadError(null);

    const formData = new FormData();
    formData.append("archivo", selectedFile);
    formData.append("nombre", documentDetails.nombre);
    formData.append("descripcion", documentDetails.descripcion);

    try {
      await dispatch(uploadDocumento(formData)).unwrap();
      dispatch(fetchDocumentos()); // Actualizar la lista de documentos
      handleCloseModal(); // Cerrar el modal
    } catch (error) {
      console.error("Error al subir el documento:", error);
      setUploadError("Error al subir el documento. Por favor, inténtalo nuevamente.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteDocument = async (id) => {
    if (window.confirm("¿Estás seguro de eliminar este documento?")) {
      try {
        await dispatch(deleteDocumento(id)).unwrap();
        dispatch(fetchDocumentos()); // Actualizar la lista de documentos
      } catch (error) {
        console.error("Error al eliminar el documento:", error);
      }
    }
  };

  return (
    <div>
      <h1>Gestión de Documentos</h1>
      <Button onClick={handleShowModal} className="mb-3">
        Subir Documento
      </Button>

      {loading ? (
        <Spinner animation="border" />
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Título</th>
              <th>Descripción</th>
              <th>Fecha de Subida</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {documentos.map((doc) => (
              <tr key={doc.id}>
                <td>{doc.id}</td>
                <td>{doc.titulo}</td>
                <td>{doc.descripcion || "Sin descripción"}</td>
                <td>{new Date(doc.fecha_subida).toLocaleDateString("es-CL")}</td>
                <td>
                  <Button
                    variant="primary"
                    href={doc.url}
                    target="_blank"
                    className="me-2"
                  >
                    Ver
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteDocument(doc.id)}
                  >
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal para subir documentos */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Subir Documento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {uploadError && <Alert variant="danger">{uploadError}</Alert>}
          <Form>
            <Form.Group className="mb-3" controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={documentDetails.nombre}
                onChange={(e) =>
                  setDocumentDetails({ ...documentDetails, nombre: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={documentDetails.descripcion}
                onChange={(e) =>
                  setDocumentDetails({
                    ...documentDetails,
                    descripcion: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="archivo">
              <Form.Label>Archivo</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf,.docx,.txt"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleUploadDocument}
            disabled={!selectedFile || isUploading}
          >
            {isUploading ? "Subiendo..." : "Subir"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DocumentosAdminPage;
