import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEncuestas, crearEncuesta, eliminarEncuesta, fetchPreguntasByEncuestaId } from '../../redux/encuestasSlice';
import { Table, Button, Modal, Form, Alert } from 'react-bootstrap';
import api from '../../utils/axiosConfig';

const EncuestasPage = () => {
  const dispatch = useDispatch();
  const { encuestas, error, preguntas } = useSelector((state) => state.encuestas);

  const [showModal, setShowModal] = useState(false);
  const [showPreguntaModal, setShowPreguntaModal] = useState(false);
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [pregunta, setPregunta] = useState('');
  const [tipoRespuesta, setTipoRespuesta] = useState('opcion_multiple');
  const [selectedEncuestaId, setSelectedEncuestaId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editPreguntaId, setEditPreguntaId] = useState(null);
  const [errorEncuesta, setErrorEncuesta] = useState(null);
  const [errorPregunta, setErrorPregunta] = useState(null);

  useEffect(() => {
    dispatch(fetchEncuestas());
  }, [dispatch]);

  const handleCrearEncuesta = () => {
    if (!titulo.trim()) {
      setErrorEncuesta('El título de la encuesta es obligatorio.');
      return;
    }
    const nuevaEncuesta = { titulo, descripcion, activo: true };
    dispatch(crearEncuesta(nuevaEncuesta))
      .unwrap()
      .then(() => {
        setShowModal(false);
        setTitulo('');
        setDescripcion('');
        setErrorEncuesta(null);
      })
      .catch((err) => {
        console.error('Error al crear encuesta:', err);
        setErrorEncuesta(err.message || 'Error al crear la encuesta');
      });
  };

  const handleEliminarEncuesta = (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta encuesta?')) {
      dispatch(eliminarEncuesta(id)).catch((err) => {
        console.error('Error al eliminar encuesta:', err);
      });
    }
  };

  const handleAbrirModalPregunta = (id) => {
    setSelectedEncuestaId(id);
    dispatch(fetchPreguntasByEncuestaId(id));
    setShowPreguntaModal(true);
  };

  const updatePreguntas = () => {
    dispatch(fetchPreguntasByEncuestaId(selectedEncuestaId));
  };

  const handleCrearPregunta = async () => {
    if (!pregunta.trim()) {
      setErrorPregunta('La pregunta no puede estar vacía.');
      return;
    }
    try {
      setErrorPregunta(null);
      if (isEditing) {
        await api.put(`/api/encuestas/preguntas/${editPreguntaId}`, {
          pregunta,
          tipo_respuesta: tipoRespuesta,
        });
        alert('Pregunta actualizada exitosamente.');
      } else {
        await api.post(`/api/encuestas/${selectedEncuestaId}/preguntas`, {
          pregunta,
          tipo_respuesta: tipoRespuesta,
        });
        alert('Pregunta añadida exitosamente.');
      }
      updatePreguntas();
      setPregunta('');
      setTipoRespuesta('opcion_multiple');
      setIsEditing(false);
    } catch (error) {
      console.error('Error al gestionar la pregunta:', error);
      setErrorPregunta(error.response?.data?.message || 'Error al gestionar la pregunta');
    }
  };

  const handleEliminarPregunta = async (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta pregunta?')) {
      try {
        await api.delete(`/api/encuestas/preguntas/${id}`);
        updatePreguntas();
        alert('Pregunta eliminada exitosamente.');
      } catch (error) {
        console.error('Error al eliminar pregunta:', error);
        alert(error.response?.data?.message || 'Error al eliminar la pregunta.');
      }
    }
  };

  const handleEditarPregunta = (id, preguntaText, tipo) => {
    setEditPreguntaId(id);
    setPregunta(preguntaText);
    setTipoRespuesta(tipo);
    setIsEditing(true);
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center">Gestión de Encuestas</h1>

      {error && <Alert variant="danger">{error.message || 'Ocurrió un error inesperado'}</Alert>}
      {errorEncuesta && <Alert variant="danger">{errorEncuesta}</Alert>}
      {errorPregunta && <Alert variant="danger">{errorPregunta}</Alert>}

      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={() => setShowModal(true)}>
          Crear Encuesta
        </Button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Título</th>
            <th>Descripción</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {encuestas.length > 0 ? (
            encuestas.map((encuesta, index) => (
              <tr key={encuesta.id}>
                <td>{index + 1}</td>
                <td>{encuesta.titulo}</td>
                <td>{encuesta.descripcion}</td>
                <td>{encuesta.activo ? 'Sí' : 'No'}</td>
                <td>
                  <Button
                    variant="primary"
                    size="sm"
                    className="me-2"
                    onClick={() => handleAbrirModalPregunta(encuesta.id)}
                  >
                    Gestionar Preguntas
                  </Button>
                  <Button variant="danger" size="sm" onClick={() => handleEliminarEncuesta(encuesta.id)}>
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No hay encuestas disponibles
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Crear Nueva Encuesta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese el título de la encuesta"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Ingrese una descripción opcional"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleCrearEncuesta}>
            Crear
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPreguntaModal} onHide={() => setShowPreguntaModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Gestionar Preguntas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Pregunta</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese la pregunta"
                value={pregunta}
                onChange={(e) => setPregunta(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tipo de Respuesta</Form.Label>
              <Form.Select
                value={tipoRespuesta}
                onChange={(e) => setTipoRespuesta(e.target.value)}
              >
                <option value="opcion_multiple">Opción Múltiple</option>
                <option value="abierta">Abierta</option>
              </Form.Select>
            </Form.Group>
          </Form>
          <Button variant="primary" className="me-2" onClick={handleCrearPregunta}>
            {isEditing ? 'Actualizar' : 'Añadir'}
          </Button>
          {isEditing && (
            <Button variant="secondary" onClick={() => setIsEditing(false)}>
              Cancelar
            </Button>
          )}
          <hr />
          <h5>Preguntas:</h5>
          <ul>
            {(preguntas[selectedEncuestaId] || []).map((preg) => (
              <li key={preg.id}>
                {preg.pregunta} - ({preg.tipo_respuesta})
                <Button
                  variant="warning"
                  size="sm"
                  className="ms-2"
                  onClick={() => handleEditarPregunta(preg.id, preg.pregunta, preg.tipo_respuesta)}
                >
                  Editar
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  className="ms-2"
                  onClick={() => handleEliminarPregunta(preg.id)}
                >
                  Eliminar
                </Button>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPreguntaModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EncuestasPage;
