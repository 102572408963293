import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const DirectivaSindicatoPage = () => {
  // Datos temporales de los dirigentes
  const dirigentes = [
    { id: 1, nombre: 'Dirigente 1', cargo: 'Cargo 1', email: 'email1@example.com', imagen: 'https://api.sinterec.cl/public/images/150.png' },
    { id: 2, nombre: 'Dirigente 2', cargo: 'Cargo 2', email: 'email2@example.com', imagen: 'https://api.sinterec.cl/public/images/150.png' },
    { id: 3, nombre: 'Dirigente 3', cargo: 'Cargo 3', email: 'email3@example.com', imagen: 'https://api.sinterec.cl/public/images/150.png' },
    { id: 4, nombre: 'Dirigente 4', cargo: 'Cargo 4', email: 'email4@example.com', imagen: 'https://api.sinterec.cl/public/images/150.png' },
    { id: 5, nombre: 'Dirigente 5', cargo: 'Cargo 5', email: 'email5@example.com', imagen: 'https://api.sinterec.cl/public/images/150.png' },
  ];

  return (
    <Container className="mt-4">
      <h1 className="text-center mb-4">Directiva del Sindicato</h1>
      <Row className="gy-4">
        {dirigentes.map((dirigente) => (
          <Col key={dirigente.id} md={6} lg={4}>
            <Card className="shadow-sm">
              <Card.Img variant="top" src={dirigente.imagen} alt={`Foto de ${dirigente.nombre}`} />
              <Card.Body>
                <Card.Title>{dirigente.nombre}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{dirigente.cargo}</Card.Subtitle>
                <Card.Text>
                  <strong>Email:</strong> {dirigente.email || 'Sin información'}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default DirectivaSindicatoPage;
