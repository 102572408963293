import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

const GlobalSpinner = () => {
  const isLoading = useSelector((state) => state.loading.isLoading); // Obtiene el estado global

  if (!isLoading) return null; // No renderiza nada si no está cargando

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1050,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation="border" variant="dark" />
    </div>
  );
};

export default GlobalSpinner;
