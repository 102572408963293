import React, { useEffect } from 'react';
import { Navbar, Nav, Container, Button, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { logoutAsync } from '../redux/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import AdminPanel from './Dashboard/AdminPanel';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

import '../styles/logo.css';

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Obtener datos del estado global (Redux)
  const usuario = useSelector((state) => state.auth.usuario);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const cambioClave = useSelector((state) => state.auth.cambioClave);

  // Redirigir a la página de cambio de contraseña si es necesario
  useEffect(() => {
    if (isAuthenticated && cambioClave) {
      navigate('/cambio-clave'); // Redirigir a la página de cambio de contraseña
    }
  }, [isAuthenticated, cambioClave, navigate]);

  // Desestructurar datos del usuario
  const nombre = usuario?.nombre || '';
  const apellido = usuario?.apellido || '';

  // Función para manejar el logout
  const handleLogout = () => {
    dispatch(logoutAsync())
      .unwrap()
      .then(() => {
        navigate('/login'); // Redirige al login después del logout exitoso
      })
      .catch((error) => {
        console.error('Error al cerrar sesión:', error);
        alert('No se pudo cerrar la sesión. Inténtalo nuevamente.');
      });
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Navbar principal */}
      <Navbar bg="warning" expand="lg" variant="light" className="mb-2">
        <Container>
          <Navbar.Brand href="/">
            <img
              src="https://api.sinterec.cl/public/images/logo_s_test4.jpeg"
              alt="Logo Sinterec"
              id="sinterec-logo" // ID para personalizar en CSS
              className="img-fluid d-inline-block align-top"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Inicio</Nav.Link>
              <Nav.Link as={Link} to="/noticias">Noticias</Nav.Link>
              <Nav.Link as={Link} to="/directiva-sindicato">Directiva Sindicato</Nav.Link>
              {isAuthenticated && <Nav.Link as={Link} to="/documentos">Documentos</Nav.Link>}
              {!isAuthenticated && <Nav.Link as={Link} to="/login">Mi Sindicato</Nav.Link>}

              
            </Nav>

            {/* Mostrar nombre del usuario y botón de logout si está autenticado */}
            {isAuthenticated && (
              <Card id="card_welcome">
                <Nav className="ms-auto d-flex align-items-center">
                  <span className="me-3">
                    Bienvenido, <strong>{nombre} {apellido}</strong>
                  </span>
                  <Button variant="outline-dark" onClick={handleLogout}>
                    Cerrar Sesión <FontAwesomeIcon icon={faPowerOff} fade size="sm" style={{ color: "#d90202" }} />
                  </Button>
                </Nav>
              </Card>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

{/* Navbar adicional para usuarios autenticados */}
{isAuthenticated && (
  <Container fluid>
    <Navbar bg="dark" expand="lg" data-bs-theme="dark" className="mb-4">
      <Navbar.Brand href="/perfil" id="home_socio_">Home del Socio<FontAwesomeIcon icon={faUserCircle} beat size="sm" style={{ color: "#FFD43B" }} /></Navbar.Brand>
      <Navbar.Toggle aria-controls="auth-navbar-nav" />
      <Navbar.Collapse id="auth-navbar-nav">
        <Nav className="mx-auto"> {/* Centra los enlaces */}
        <Nav.Link as={Link} to="/profile">Perfil</Nav.Link>
        <Nav.Link as={Link} to="/encuestas">Encuestas</Nav.Link>
        <Nav.Link as={Link} to="/configuracion">Configuración</Nav.Link>
        </Nav>
        {/* Mostrar opción para administradores */}
        {isAdmin && (
          <Nav>
            
             {isAdmin && <AdminPanel />}
          </Nav>
        )}
      </Navbar.Collapse>
    </Navbar>
  </Container>
)}




      {/* Contenido principal */}
      <Container className="flex-grow-1">
        <Outlet />
      </Container>

      {/* Footer */}
      <footer className="bg-dark text-light py-3 mt-4">
        <Container className="d-flex justify-content-between align-items-center">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} SINTEREC CLARO. Todos los derechos reservados.
          </p>
          <a
            href="https://github.com/WilldiazRaM"
            target="_blank"
            rel="noopener noreferrer"
            className="text-light"
            style={{ fontSize: '1.5rem' }} // Ajusta el tamaño del ícono si lo necesitas
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </Container>
      </footer>
    </div>
  );
};

export default Layout;
