import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';

// Thunks
// Obtener todas las noticias
export const fetchNoticias = createAsyncThunk(
  'noticias/fetchNoticias',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/noticias/ver_noticias');
      return response.data;
    } catch (error) {
      console.error('Error al obtener noticias:', error);
      return rejectWithValue(error.response?.data || 'Error al obtener noticias');
    }
  }
);

// Obtener una noticia por ID
export const fetchNoticiaById = createAsyncThunk(
  'noticias/fetchNoticiaById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/noticias/ver_noticia/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error al obtener la noticia con ID ${id}:`, error);
      return rejectWithValue(error.response?.data || 'Error al obtener la noticia');
    }
  }
);

// Crear una nueva noticia
export const createNoticia = createAsyncThunk(
  'noticias/createNoticia',
  async (noticia, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/noticias/agregar', noticia);
      return response.data;
    } catch (error) {
      console.error('Error al crear la noticia:', error);
      return rejectWithValue(error.response?.data || 'Error al crear la noticia');
    }
  }
);

// Actualizar una noticia
export const updateNoticia = createAsyncThunk(
  'noticias/updateNoticia',
  async ({ id, noticia }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/api/noticias/actualizar_noticia/${id}`, noticia);
      return response.data;
    } catch (error) {
      console.error(`Error al actualizar la noticia con ID ${id}:`, error);
      return rejectWithValue(error.response?.data || 'Error al actualizar la noticia');
    }
  }
);

// Eliminar o desactivar una noticia
export const deleteNoticia = createAsyncThunk(
  'noticias/deleteNoticia',
  async (id, { rejectWithValue }) => {
    try {
      await api.delete(`/api/noticias/eliminar/${id}`);
      return id;
    } catch (error) {
      console.error(`Error al eliminar la noticia con ID ${id}:`, error);
      return rejectWithValue(error.response?.data || 'Error al eliminar la noticia');
    }
  }
);

const noticiasSlice = createSlice({
  name: 'noticias',
  initialState: {
    noticias: [],
    noticiaSeleccionada: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearNoticiaSeleccionada: (state) => {
      state.noticiaSeleccionada = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch todas las noticias
      .addCase(fetchNoticias.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNoticias.fulfilled, (state, action) => {
        state.loading = false;
        state.noticias = action.payload;
      })
      .addCase(fetchNoticias.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch noticia por ID
      .addCase(fetchNoticiaById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNoticiaById.fulfilled, (state, action) => {
        state.loading = false;
        state.noticiaSeleccionada = action.payload;
      })
      .addCase(fetchNoticiaById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Crear noticia
      .addCase(createNoticia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNoticia.fulfilled, (state, action) => {
        state.loading = false;
        state.noticias.push(action.payload);
      })
      .addCase(createNoticia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Actualizar noticia
      .addCase(updateNoticia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNoticia.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.noticias.findIndex((noticia) => noticia.id === action.payload.id);
        if (index !== -1) {
          state.noticias[index] = action.payload;
        }
      })
      .addCase(updateNoticia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Eliminar noticia
      .addCase(deleteNoticia.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNoticia.fulfilled, (state, action) => {
        state.loading = false;
        state.noticias = state.noticias.filter((noticia) => noticia.id !== action.payload);
      })
      .addCase(deleteNoticia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearNoticiaSeleccionada } = noticiasSlice.actions;
export default noticiasSlice.reducer;
