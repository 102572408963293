import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';

// Thunks
// Obtener todos los documentos
export const fetchDocumentos = createAsyncThunk(
  'documentos/fetchDocumentos',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/documentos/listar');
      return response.data;
    } catch (error) {
      // Devuelve un error estructurado para el middleware
      return rejectWithValue({
        message: error.response?.data?.message || 'Error al obtener documentos',
        status: error.response?.status || 500,
      });
    }
  }
);

// Subir un documento
export const uploadDocumento = createAsyncThunk(
  'documentos/uploadDocumento',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/documentos/subir', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al subir documento');
    }
  }
);

// Descargar un documento
export const downloadDocumento = createAsyncThunk(
  'documentos/downloadDocumento',
  async (nombre, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/documentos/descargar/${nombre}`, {
        responseType: 'blob', // Indica que se espera un archivo como respuesta
      });
      return { blob: response.data, nombre };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al descargar documento');
    }
  }
);

// Eliminar un documento
export const deleteDocumento = createAsyncThunk(
  'documentos/deleteDocumento',
  async (nombre, { rejectWithValue }) => {
    try {
      await api.delete(`/api/documentos/eliminar/${nombre}`);
      return nombre;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al eliminar documento');
    }
  }
);

const documentosSlice = createSlice({
  name: 'documentos',
  initialState: {
    documentos: [],
    documentoSeleccionado: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearDocumentoSeleccionado: (state) => {
      state.documentoSeleccionado = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Obtener documentos
      .addCase(fetchDocumentos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDocumentos.fulfilled, (state, action) => {
        state.loading = false;
        state.documentos = Array.isArray(action.payload) ? action.payload : [];
      })      
      .addCase(fetchDocumentos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Subir documento
      .addCase(uploadDocumento.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadDocumento.fulfilled, (state, action) => {
        state.loading = false;
        state.documentos.push(action.payload);
      })
      .addCase(uploadDocumento.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Descargar documento
      .addCase(downloadDocumento.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadDocumento.fulfilled, (state, action) => {
        state.loading = false;
        state.documentoSeleccionado = action.payload;
      })
      .addCase(downloadDocumento.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Eliminar documento
      .addCase(deleteDocumento.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDocumento.fulfilled, (state, action) => {
        state.loading = false;
        state.documentos = state.documentos.filter((doc) => doc.nombre !== action.payload);
      })
      .addCase(deleteDocumento.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearDocumentoSeleccionado } = documentosSlice.actions;
export default documentosSlice.reducer;
