import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { clearError } from '../redux/errorSlice';

const GlobalError = () => {
    const dispatch = useDispatch();
    const { message, status } = useSelector((state) => state.error);

    if (!message) return null; // No muestra nada si no hay error

    return (
        <Alert
            variant="danger"
            onClose={() => dispatch(clearError())}
            dismissible
            style={{
                position: 'fixed',
                top: '10px',
                right: '10px',
                zIndex: 1050,
            }}
        >
            <strong>Error {status}: </strong> {message}
        </Alert>
    );
};

export default GlobalError;
