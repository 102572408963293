import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Spinner, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNoticias,
  createNoticia,
  updateNoticia,
  deleteNoticia,
} from "../../redux/noticiasSlice";

const NoticiasAdminPage = () => {
  const dispatch = useDispatch();
  const { noticias, loading, error } = useSelector((state) => state.noticias);

  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentNoticia, setCurrentNoticia] = useState({
    titulo: "",
    descripcion: "",
    fecha_publicacion: "",
    imagen: null,
  });
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    dispatch(fetchNoticias());
  }, [dispatch]);

  const handleCloseModal = () => {
    setValidationErrors([]);
    setShowModal(false);
    setIsEditMode(false);
    setCurrentNoticia({
      titulo: "",
      descripcion: "",
      fecha_publicacion: "",
      imagen: null,
    });
  };

  const handleShowModal = (noticia = null) => {
    if (noticia) {
      setCurrentNoticia({ ...noticia, imagen: null }); // No cargamos imagen previa en edición
      setIsEditMode(true);
    }
    setValidationErrors([]);
    setShowModal(true);
  };

  const validateFields = () => {
    const errors = [];
    if (!currentNoticia.titulo.trim()) {
      errors.push("El título es obligatorio.");
    } else if (currentNoticia.titulo.length > 255) {
      errors.push("El título no debe exceder los 255 caracteres.");
    }
    if (!currentNoticia.descripcion.trim()) {
      errors.push("La descripción es obligatoria.");
    } else if (currentNoticia.descripcion.length < 10) {
      errors.push("La descripción debe tener al menos 10 caracteres.");
    }
    if (!currentNoticia.fecha_publicacion) {
      errors.push("La fecha de publicación es obligatoria.");
    } else if (new Date(currentNoticia.fecha_publicacion) > new Date()) {
      errors.push("La fecha de publicación no puede ser futura.");
    }
    if (!isEditMode && !currentNoticia.imagen) {
      errors.push("La imagen es obligatoria al crear una noticia.");
    }
    return errors;
  };

  const handleSaveNoticia = async () => {
    const errors = validateFields();
    if (errors.length > 0) {
      setValidationErrors(errors);
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append("titulo", currentNoticia.titulo);
      formData.append("descripcion", currentNoticia.descripcion);
      formData.append("fecha_publicacion", currentNoticia.fecha_publicacion);
      if (currentNoticia.imagen) {
        formData.append("imagen", currentNoticia.imagen); // Asegúrate de que currentNoticia.imagen contiene el archivo.
      }
  
      console.log("Datos enviados al backend:", {
        titulo: currentNoticia.titulo,
        descripcion: currentNoticia.descripcion,
        fecha_publicacion: currentNoticia.fecha_publicacion,
        imagen: currentNoticia.imagen ? currentNoticia.imagen.name : "No hay imagen",
      });
      console.log("FormData antes de enviar:", Array.from(formData.entries()));
  
      if (isEditMode) {
        await dispatch(updateNoticia({ id: currentNoticia.id, noticia: formData })).unwrap();
      } else {
        await dispatch(createNoticia(formData)).unwrap();
      }
  
      console.log("Noticia guardada exitosamente.");
      dispatch(fetchNoticias());
      handleCloseModal();
    } catch (error) {
      console.error("Error al guardar la noticia:", error);
  
      if (
        error &&
        error.response &&
        error.response.data &&
        Array.isArray(error.response.data.errors)
      ) {
        setValidationErrors(
          error.response.data.errors.map((err) => err.message || "Error desconocido.")
        );
      } else {
        setValidationErrors(["Error desconocido al guardar la noticia."]);
      }
    }
  };
  
  
  

  const handleDeleteNoticia = async (id) => {
    if (window.confirm("¿Estás seguro de eliminar esta noticia?")) {
      try {
        await dispatch(deleteNoticia(id)).unwrap();
        dispatch(fetchNoticias());
      } catch (error) {
        console.error("Error al eliminar la noticia:", error);
      }
    }
  };

  return (
    <div>
      <h1>Gestión de Noticias</h1>
      <Button onClick={() => handleShowModal()} className="mb-3">
        Crear Noticia
      </Button>

      {loading ? (
        <Spinner animation="border" />
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Título</th>
              <th>Descripción</th>
              <th>Fecha de Publicación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {noticias.map((noticia) => (
              <tr key={noticia.id}>
                <td>{noticia.id}</td>
                <td>{noticia.titulo}</td>
                <td>{noticia.descripcion}</td>
                <td>{noticia.fecha_publicacion}</td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => handleShowModal(noticia)}
                    className="me-2"
                  >
                    Editar
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteNoticia(noticia.id)}
                  >
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? "Editar Noticia" : "Crear Noticia"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {validationErrors.length > 0 && (
            <Alert variant="danger">
              <ul>
                {validationErrors.map((err, index) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            </Alert>
          )}


          <Form>
            <Form.Group className="mb-3" controlId="titulo">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                value={currentNoticia.titulo}
                onChange={(e) =>
                  setCurrentNoticia({ ...currentNoticia, titulo: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={currentNoticia.descripcion}
                onChange={(e) =>
                  setCurrentNoticia({ ...currentNoticia, descripcion: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="fecha_publicacion">
              <Form.Label>Fecha de Publicación</Form.Label>
              <Form.Control
                type="date"
                value={currentNoticia.fecha_publicacion}
                onChange={(e) =>
                  setCurrentNoticia({
                    ...currentNoticia,
                    fecha_publicacion: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="imagen">
              <Form.Label>Imagen</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) =>
                  
                  setCurrentNoticia({ ...currentNoticia, imagen: e.target.files[0] })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveNoticia}>
            {isEditMode ? "Actualizar" : "Guardar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NoticiasAdminPage;
