import { logout } from '../authSlice';

const errorMiddleware = (store) => (next) => (action) => {
  if (action.type.endsWith('/rejected')) {
    const errorMessage = action.payload?.message || 'Ha ocurrido un error inesperado.';
    const status = action.payload?.status || 500;

    // Verificar si es el mensaje de sesión expirada
    if (errorMessage === 'La sesión ha expirado o es inválida. Por favor, vuelve a iniciar sesión.') {
      // Despachar la acción de logout
      store.dispatch(logout());
      
      // Redirigir al inicio de sesión
      window.location.href = '/login';
    }

    // Actualizar el estado global de errores en Redux
    store.dispatch({
      type: 'error/setError',
      payload: { message: errorMessage, status },
    });
  }
  return next(action);
};

export default errorMiddleware;
