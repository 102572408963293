import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import LoginComponent from '../components/Auth/LoginComponent';

const LoginPage = () => {
  return (
    <Container>
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col md={6} lg={4}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Iniciar Sesión</h2>
              <LoginComponent />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
