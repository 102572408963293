import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    currentTheme: localStorage.getItem('theme') || 'system', // Guardar la preferencia del tema
  },
  reducers: {
    setTheme: (state, action) => {
      state.currentTheme = action.payload;
      localStorage.setItem('theme', action.payload); // Persistir en localStorage
    },
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
