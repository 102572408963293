import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';

// Obtener el perfil del usuario autenticado
export const obtenerPerfil = createAsyncThunk(
  'profile/obtenerPerfil',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/auth/profile');
      return response.data.usuario;
    } catch (error) {
      const message = error.response?.data?.message || 'Error al obtener el perfil';
      if (message === 'La sesión ha expirado o es inválida. Por favor, vuelve a iniciar sesión.') {
        return rejectWithValue({ type: 'sessionExpired', message });
      }
      return rejectWithValue({ message });
    }
  }
);

// Actualizar el perfil del usuario
export const actualizarPerfil = createAsyncThunk(
  'profile/actualizarPerfil',
  async (datos, { rejectWithValue }) => {
    try {
      const response = await api.put('/auth/profile', datos);
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Error al actualizar el perfil');
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    usuario: {}, // Evitar problemas con valores `null`
    loading: false,
    error: null,
    mensaje: null,
  },
  reducers: {
    limpiarMensaje: (state) => {
      state.mensaje = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Obtener perfil
      .addCase(obtenerPerfil.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(obtenerPerfil.fulfilled, (state, action) => {
        state.loading = false;
        state.usuario = action.payload;
      })
      .addCase(obtenerPerfil.rejected, (state, action) => {
        state.loading = false;
        if (action.payload?.type === 'sessionExpired') {
          state.error = action.payload.message;
          // Aquí podrías despachar logout desde el middleware o slice
        } else {
          state.error = action.payload.message;
        }
      })
      // Actualizar perfil
      .addCase(actualizarPerfil.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(actualizarPerfil.fulfilled, (state, action) => {
        state.loading = false;
        state.mensaje = action.payload;
      })
      .addCase(actualizarPerfil.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { limpiarMensaje } = profileSlice.actions;
export default profileSlice.reducer;
