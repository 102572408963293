import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../redux/authSlice';
import loadingReducer from '../redux/loadingSlice'
import noticiasReducer from '../redux/noticiasSlice';
import documentosReducer from '../redux/documentosSlice';
import beneficiosReducer from '../redux/beneficiosSlice';
import profileReducer from '../redux/profileSlice';
import errorReducer from '../redux/errorSlice';
import errorMiddleware from './middlewares/errorMiddleware';
import themeReducer from '../redux/themeSlice';
import correoReducer from '../redux/correosSlice'
import encuestasReducer from '../redux/encuestasSlice'
import dashboardReducer from '../redux/dashboardSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    correo: correoReducer,
    error: errorReducer,
    profile: profileReducer, 
    loading: loadingReducer,
    noticias: noticiasReducer,
    documentos: documentosReducer,
    beneficios: beneficiosReducer,
    theme: themeReducer,
    encuestas: encuestasReducer,
    dashboard: dashboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(errorMiddleware),
});

export default store;
