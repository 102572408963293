import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from '../redux/themeSlice';

const ConfiguracionPage = () => {
  const dispatch = useDispatch();
  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const handleThemeChange = (theme) => {
    dispatch(setTheme(theme));
    document.body.setAttribute('data-theme', theme); // Cambiar atributo del body para los estilos globales
  };

  return (
    <div className="container mt-5">
      <h2>Configuración</h2>
      <div>
        <h4>Selecciona el tema</h4>
        <div>
          <button
            className={`btn ${currentTheme === 'system' ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => handleThemeChange('system')}
          >
            Sistema (Predeterminado)
          </button>
          <button
            className={`btn ${currentTheme === 'white' ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => handleThemeChange('white')}
          >
            Claro
          </button>
          <button
            className={`btn ${currentTheme === 'black' ? 'btn-primary' : 'btn-outline-primary'}`}
            onClick={() => handleThemeChange('black')}
          >
            Oscuro
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfiguracionPage;
