import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNoticias, fetchNoticiaById, clearNoticiaSeleccionada } from '../redux/noticiasSlice';
import { Container, Row, Col, Card, Button, Spinner } from 'react-bootstrap';

const NoticiasPage = () => {
    const dispatch = useDispatch();

    // Acceso al estado global
    const { noticias, noticiaSeleccionada, loading, error } = useSelector((state) => state.noticias);

    // Estado local para controlar el detalle de la noticia
    const [mostrarDetalle, setMostrarDetalle] = useState(false);

    // Cargar noticias al montar el componente
    useEffect(() => {
        dispatch(fetchNoticias());
    }, [dispatch]);

    // Manejar clic en una noticia para mostrar detalles
    const handleVerDetalles = (id) => {
        dispatch(fetchNoticiaById(id));
        setMostrarDetalle(true);
    };

    // Manejar clic para volver a la lista de noticias
    const handleVolver = () => {
        setMostrarDetalle(false);
        dispatch(clearNoticiaSeleccionada());
    };

    return (
        <Container className="my-5">
            <h1 className="text-center mb-4">Noticias</h1>
            {loading && (
                <div className="text-center">
                    <Spinner animation="border" variant="primary" />
                    <p>Cargando noticias...</p>
                </div>
            )}
            {error && <p className="text-danger text-center">Error: {error}</p>}

            {!loading && noticias.length === 0 && (
                <p className="text-center">No hay noticias disponibles en este momento.</p>
            )}

            {!mostrarDetalle && !loading && noticias.length > 0 && (
                <Row>
                    {noticias.map((noticia) => (
                        <Col key={noticia.id} xs={12} md={6} lg={4} className="mb-4">
                            <Card>
                                <Card.Img
                                    variant="top"
                                    src={noticia.imagen_url ? noticia.imagen_url : '/placeholder.jpg'}
                                    alt={noticia.titulo}
                                    style={{ height: '200px', objectFit: 'cover' }}
                                />
                                <Card.Body>
                                    <Card.Title>{noticia.titulo}</Card.Title>
                                    <Card.Text>
                                        {noticia.descripcion.substring(0, 100)}...
                                    </Card.Text>
                                    <Button variant="primary" onClick={() => handleVerDetalles(noticia.id)}>
                                        Ver más
                                    </Button>
                                </Card.Body>
                            </Card>

                        </Col>
                    ))}
                </Row>
            )}

            {mostrarDetalle && noticiaSeleccionada && (
                <div className="text-center">
                    <Button variant="success" className="mb-4" onClick={handleVolver}>
                        Volver
                    </Button>
                    <Card>
                        <Card.Img
                            variant="top"
                            src={
                                noticiaSeleccionada.imagen_url
                                    ? noticiaSeleccionada.imagen_url
                                    : '/placeholder.jpg'
                            }
                            alt={noticiaSeleccionada.titulo}
                            style={{ height: '400px', objectFit: 'cover' }}
                        />
                        <Card.Body>
                            <Card.Title>{noticiaSeleccionada.titulo}</Card.Title>
                            <Card.Text>{noticiaSeleccionada.descripcion}</Card.Text>
                            <Card.Text className="text-muted">
                                Publicada el: {noticiaSeleccionada.fecha_publicacion}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            )}
        </Container>
    );
};

export default NoticiasPage;
