import React, { useState } from "react";
import { Table, Button, Form, InputGroup } from "react-bootstrap";




const ListarUsuarios = ({ usuarios, eliminarUsuario, openEditModal, openEditUserModal, fetchUsuarios }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Ordenar usuarios
  const sortedUsuarios = [...usuarios].sort((a, b) => {
    if (!sortConfig.key) return 0; // Sin orden

    // Obtener los valores de las claves a ordenar
    const aValue = a[sortConfig.key]?.toString().toLowerCase() || "";
    const bValue = b[sortConfig.key]?.toString().toLowerCase() || "";

    if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  // Filtrar usuarios
  const filteredUsuarios = sortedUsuarios.filter(
    (usuario) =>
      usuario.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      `${usuario.nombre} ${usuario.primer_apellido} ${usuario.segundo_apellido}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  // Cambiar la configuración de orden
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <div>
      <h2>Usuarios Registrados</h2>

      {/* Barra de búsqueda */}
      <InputGroup className="mb-3">
        <Form.Control
          type="text"
          placeholder="Buscar por email o nombre completo"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button variant="outline-secondary" onClick={fetchUsuarios}>
          Actualizar Usuarios
        </Button>
      </InputGroup>

      {/* Tabla responsiva */}
      {filteredUsuarios.length > 0 ? (
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th onClick={() => handleSort("id")} style={{ cursor: "pointer" }}>
                  ID {sortConfig.key === "id" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                </th>
                <th onClick={() => handleSort("nombre")} style={{ cursor: "pointer" }}>
                  Nombre {sortConfig.key === "nombre" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                </th>
                <th onClick={() => handleSort("email")} style={{ cursor: "pointer" }}>
                  Email {sortConfig.key === "email" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                </th>
                <th onClick={() => handleSort("rol")} style={{ cursor: "pointer" }}>
                  Rol {sortConfig.key === "rol" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                </th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsuarios.map((usuario) => (
                <tr key={usuario.id}>
                  <td>{usuario.id}</td>
                  <td>
                    {usuario.nombre} {usuario.primer_apellido} {usuario.segundo_apellido}
                  </td>
                  <td>{usuario.email}</td>
                  <td>{usuario.rol}</td>
                  <td>
                    <Button
                      variant="warning"
                      onClick={() => openEditModal(usuario)}
                      className="me-2"
                    >
                      Editar Rol
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => openEditUserModal(usuario)} // Llamada a la función pasada desde UsuariosPage.js
                      className="me-2"
                    >
                      Modificar
                    </Button>


                    <Button
                      variant="danger"
                      onClick={() => eliminarUsuario(usuario.id)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p>No hay usuarios registrados o no coinciden con la búsqueda.</p>
      )}
    </div>
  );
};

export default ListarUsuarios;
