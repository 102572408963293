import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEncuestasPendientes, responderEncuesta } from '../../redux/encuestasSlice';
import { Button, Modal, Form, Alert } from 'react-bootstrap';

const RencuestaPage = () => {
  const dispatch = useDispatch();
  const { encuestasPendientes, loading, error } = useSelector((state) => state.encuestas);

  const [showModal, setShowModal] = useState(false);
  const [selectedEncuesta, setSelectedEncuesta] = useState(null);
  const [preguntas, setPreguntas] = useState([]);
  const [respuestas, setRespuestas] = useState({});
  const [responseError, setResponseError] = useState(null);

  useEffect(() => {
    dispatch(fetchEncuestasPendientes());
  }, [dispatch]);

  const handleOpenModal = (encuesta) => {
    setSelectedEncuesta(encuesta);
    setPreguntas(encuesta.preguntas || []);
    setRespuestas({});
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEncuesta(null);
    setPreguntas([]);
    setRespuestas({});
    setResponseError(null);
    // Refrescar encuestas pendientes
    dispatch(fetchEncuestasPendientes());
  };

  const handleRespuestaChange = (preguntaId, value) => {
    setRespuestas((prev) => ({
      ...prev,
      [preguntaId]: value,
    }));
  };

  const handleEnviarRespuestas = () => {
    if (!selectedEncuesta) return;

    const respuestasArray = Object.entries(respuestas).map(([preguntaId, respuesta]) => ({
      pregunta_id: parseInt(preguntaId, 10),
      respuesta,
    }));

    const promises = respuestasArray.map((respuesta) =>
      dispatch(
        responderEncuesta({
          encuestaId: selectedEncuesta.id,
          respuesta: { pregunta_id: respuesta.pregunta_id, respuesta: respuesta.respuesta },
        })
      ).unwrap()
    );

    Promise.all(promises)
      .then(() => {
        alert('Respuestas enviadas exitosamente.');
        // Refrescar las encuestas pendientes
        dispatch(fetchEncuestasPendientes());
        handleCloseModal();
      })
      .catch((err) => {
        console.error('Error al enviar respuestas:', err);
        setResponseError('Ocurrió un error al enviar las respuestas.');
      });
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Encuestas Pendientes</h1>
      {loading && <p>Cargando encuestas...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && encuestasPendientes?.length === 0 && (
        <p>No tienes encuestas pendientes.</p>
      )}
      {!loading && encuestasPendientes?.length > 0 && (
        <ul className="list-group">
          {encuestasPendientes.map((encuesta) => (
            <li key={encuesta.id} className="list-group-item">
              <h2>{encuesta.titulo}</h2>
              <p>{encuesta.descripcion}</p>
              <Button variant="primary" onClick={() => handleOpenModal(encuesta)}>
                Responder
              </Button>
            </li>
          ))}
        </ul>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Responder Encuesta: {selectedEncuesta?.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseError && <Alert variant="danger">{responseError}</Alert>}
          {preguntas.length > 0 ? (
            preguntas.map((pregunta) => (
              <Form.Group className="mb-3" key={pregunta.id}>
                <Form.Label>{pregunta.pregunta}</Form.Label>
                {pregunta.tipo_respuesta === 'opcion_multiple' ? (
                  <Form.Select
                    onChange={(e) => handleRespuestaChange(pregunta.id, e.target.value)}
                  >
                    <option value="">Seleccione una opción</option>
                    <option value="Sí">Sí</option>
                    <option value="No">No</option>
                  </Form.Select>
                ) : (
                  <Form.Control
                    type="text"
                    placeholder="Ingrese su respuesta"
                    onChange={(e) => handleRespuestaChange(pregunta.id, e.target.value)}
                  />
                )}
              </Form.Group>
            ))
          ) : (
            <p>Esta encuesta no tiene preguntas asociadas.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleEnviarRespuestas}>
            Enviar Respuestas
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RencuestaPage;
