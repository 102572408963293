import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';




// Acción para logout asíncrono
export const logoutAsync = createAsyncThunk('auth/logoutAsync', async (_, { rejectWithValue }) => {
  try {
    // Llama a tu backend usando la instancia configurada
    await api.get('/auth/logout'); // Cambia a GET porque tu backend usa `router.get('/logout')`
    return true; // Indica éxito
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Error al cerrar sesión');
  }
});

// Obtener perfil del usuario
export const fetchProfile = createAsyncThunk('auth/fetchProfile', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('/auth/profile');
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Error al obtener el perfil');
  }
});

// Actualizar perfil del usuario
export const updateProfile = createAsyncThunk('auth/updateProfile', async (profileData, { rejectWithValue }) => {
  try {
    const response = await api.put('/auth/profile', profileData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Error al actualizar el perfil');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('token') || null,
    isAuthenticated: !!localStorage.getItem('token'),
    isAdmin: localStorage.getItem('rol') === 'administrador', // Determinar si es admin al cargar
    isSocio: localStorage.getItem('rol') === 'socio', // Determinar si es socio al cargar
    usuario: {
      nombre: localStorage.getItem('nombre') || '',
      apellido: localStorage.getItem('apellido') || '',
      rol: localStorage.getItem('rol') || '',
    },
    cambioClave: false,
  },
  reducers: {
    login: (state, action) => {
      const { token, rol, nombre, apellido, cambio_clave } = action.payload;

      state.usuario = { rol, nombre, apellido };
      state.token = token;
      state.isAuthenticated = !!token;
      state.isAdmin = rol === 'administrador'; // Determinar si el usuario es administrador
      state.isSocio = rol === 'socio'; // Determinar si el usuario es socio
      state.cambioClave = cambio_clave || false;

      // Guardar datos en localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('nombre', nombre);
      localStorage.setItem('apellido', apellido);
      localStorage.setItem('rol', rol);
      localStorage.setItem('cambio_clave', cambio_clave);
    },
    logout: (state) => {
      state.usuario = { nombre: '', apellido: '', rol: '' };
      state.token = null;
      state.isAuthenticated = false;
      state.isAdmin = false; // Resetear el estado de admin
      state.isSocio = false; // Resetear el estado de socio
      state.cambioClave = false;

      localStorage.clear();
    },
    initializeAuth: (state) => {
      // Leer valores de localStorage al iniciar
      state.token = localStorage.getItem('token') || null;
      state.isAuthenticated = !!state.token;
      state.usuario = {
        nombre: localStorage.getItem('nombre') || '',
        apellido: localStorage.getItem('apellido') || '',
        rol: localStorage.getItem('rol') || '',
      };
      state.isAdmin = state.usuario.rol === 'administrador';
      state.isSocio = state.usuario.rol === 'socio';
      state.cambioClave = localStorage.getItem('cambio_clave') === 'true';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutAsync.fulfilled, (state) => {
        console.log('Logout exitoso: Estado antes de limpiar', state);
        state.logoutError = null;
        state.usuario = { nombre: '', apellido: '', rol: '' };
        state.token = null;
        state.isAuthenticated = false;
        state.isAdmin = false;
        state.cambioClave = false;

        localStorage.clear();
        console.log('Logout exitoso: Estado después de limpiar', state);
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        state.logoutError = action.payload;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.usuario = action.payload;
        state.error = null;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.usuario = { ...state.usuario, ...action.meta.arg }; // Actualiza el perfil localmente
        state.error = null;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});


export const { login, logout, initializeAuth } = authSlice.actions;
export default authSlice.reducer;
