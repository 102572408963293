import React, { useState } from "react";
import { Form, Button, Alert, Row, Col } from "react-bootstrap";
import api from "../../utils/axiosConfig"; // Asegúrate de importar tu configuración de Axios
import { Card } from "react-bootstrap";



const RegisterUser = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "", // Nuevo campo para confirmar contraseña
    nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    rut: "",
    dv: "",
    telefono: "",
    rol: "socio", // Valor predeterminado
    fecha_nacimiento: "",
    fecha_ingreso: "",
    estado: "activo",
    direccion: "",
    empresa: "claro", // Valor predeterminado
  });

  const [passwordMatch, setPasswordMatch] = useState(true); // Verificar si las contraseñas coinciden
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Actualizar el estado de las contraseñas y verificar si coinciden
    if (name === "password" || name === "confirmPassword") {
      setPasswordMatch(
        name === "confirmPassword" ? value === formData.password : value === formData.confirmPassword
      );
    }

    setFormData({ ...formData, [name]: value });
  };

  // Manejar envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(""); // Limpiar mensajes anteriores
    setErrors([]); // Limpiar errores anteriores

    // Verificar si las contraseñas coinciden antes de enviar
    if (!passwordMatch) {
      setErrors([{ msg: "Las contraseñas no coinciden" }]);
      return;
    }

    try {
      const response = await api.post("/auth/register", formData);

      if (response.status === 201) {
        setMessage("Usuario registrado con éxito");
        setFormData({
          email: "",
          password: "",
          confirmPassword: "",
          nombre: "",
          primer_apellido: "",
          segundo_apellido: "",
          rut: "",
          dv: "",
          telefono: "",
          rol: "socio",
          fecha_nacimiento: "",
          fecha_ingreso: "",
          estado: "activo",
          direccion: "",
          empresa: "claro",
        });
        setPasswordMatch(true); // Reiniciar el estado de contraseñas coincidentes
      }
    } catch (error) {
      if (error.response && error.response.data.errors) {
        // Capturar errores de validación del backend
        setErrors(error.response.data.errors);
      } else {
        console.error("Error inesperado al registrar usuario:", error);
        setErrors([{ msg: "Error inesperado al registrar usuario" }]);
      }
    }
  };

  return (
    <div>
      <h2>Registrar Usuario</h2>

      {/* Mostrar mensaje de éxito */}
      {message && <Alert variant="success">{message}</Alert>}

      {/* Mostrar errores */}
      {errors.length > 0 &&
        errors.map((err, index) => (
          <Alert key={index} variant="danger">
            {err.msg || "Error inesperado"}
          </Alert>
        ))}

      {/* Formulario */}
      <Card>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Correo electrónico"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Contraseña"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formConfirmPassword" className="mb-3">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                placeholder="Confirme la contraseña"
                value={formData.confirmPassword}
                onChange={handleChange}
                isInvalid={!passwordMatch}
                required
              />
              {!passwordMatch && (
                <Form.Text className="text-danger">
                  Las contraseñas no coinciden
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="formNombre" className="mb-3">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                placeholder="Nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPrimerApellido" className="mb-3">
              <Form.Label>Primer Apellido</Form.Label>
              <Form.Control
                type="text"
                name="primer_apellido"
                placeholder="Primer Apellido"
                value={formData.primer_apellido}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formSegundoApellido" className="mb-3">
              <Form.Label>Segundo Apellido</Form.Label>
              <Form.Control
                type="text"
                name="segundo_apellido"
                placeholder="Segundo Apellido"
                value={formData.segundo_apellido}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formRut" className="mb-3">
              <Form.Label>RUT</Form.Label>
              <Form.Control
                type="text"
                name="rut"
                placeholder="RUT (sin puntos ni guión)"
                value={formData.rut}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDv" className="mb-3">
              <Form.Label>Dígito Verificador</Form.Label>
              <Form.Control
                type="text"
                name="dv"
                placeholder="Dígito Verificador"
                value={formData.dv}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formTelefono" className="mb-3">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                name="telefono"
                placeholder="Ejemplo: +56912345678"
                value={formData.telefono}
                onChange={handleChange}
                required
              />
              <Form.Text className="text-muted">
                El teléfono debe incluir el código de país (+56) seguido de 9
                dígitos.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formRol" className="mb-3">
              <Form.Label>Rol</Form.Label>
              <Form.Select
                name="rol"
                value={formData.rol}
                onChange={handleChange}
                required
              >
                <option value="socio">Socio</option>
                <option value="administrador">Administrador</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formFechaNacimiento" className="mb-3">
              <Form.Label>Fecha de Nacimiento</Form.Label>
              <Form.Control
                type="date"
                name="fecha_nacimiento"
                value={formData.fecha_nacimiento}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formFechaIngreso" className="mb-3">
              <Form.Label>Fecha de Ingreso</Form.Label>
              <Form.Control
                type="date"
                name="fecha_ingreso"
                value={formData.fecha_ingreso}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDireccion" className="mb-3">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                name="direccion"
                placeholder="Dirección"
                value={formData.direccion}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formEmpresa" className="mb-3">
              <Form.Label>Empresa</Form.Label>
              <Form.Select
                name="empresa"
                value={formData.empresa}
                onChange={handleChange}
                required
              >
                <option value="claro">Claro</option>
                <option value="vtr">VTR</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
  <Col>
    <Button variant="primary" type="submit" disabled={!passwordMatch}>
      Registrar
    </Button>
    <Button 
      variant="secondary" 
      type="button" 
      onClick={() => setFormData({
        email: "",
        password: "",
        confirmPassword: "",
        nombre: "",
        primer_apellido: "",
        segundo_apellido: "",
        rut: "",
        dv: "",
        telefono: "",
        rol: "socio",
        fecha_nacimiento: "",
        fecha_ingreso: "",
        estado: "activo",
        direccion: "",
        empresa: "claro",
      })}
      style={{ marginLeft: "10px" }} // Espaciado entre botones
    >
      Limpiar
    </Button>
  </Col>
</Row>

      </Form>
      </Card>
    </div>
  );
};

export default RegisterUser;
