import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
    name: 'error',
    initialState: {
        message: null, // Almacena el mensaje del error
        status: null,  // Almacena el código de estado HTTP
    },
    reducers: {
        setError: (state, action) => {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },
        clearError: (state) => {
            state.message = null;
            state.status = null;
        },
    },
});

export const { setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
