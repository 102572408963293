import React, { useState } from 'react';
import api from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../redux/authSlice';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup'; // Importa InputGroup

const CambioContrasenaPage = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [alert, setAlert] = useState(null);
  const [error, setError] = useState(null); // Para mostrar el error si las contraseñas no coinciden
  const [validated] = useState(false); // Para controlar la validación del formulario
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Obtener el token desde el estado global de Redux
  const token = useSelector((state) => state.auth.token);
  console.log(token);

  // Función para manejar el cambio de contraseña
  const handleChangePassword = async (e) => {
    e.preventDefault();

    // Validar que las contraseñas coincidan y tengan al menos 6 caracteres
    if (password !== passwordConfirm) {
      setError('Las contraseñas no coinciden');
      return; // No envía la solicitud si las contraseñas no coinciden
    }
    if (password.length < 6) {
      setError('La contraseña debe tener al menos 6 caracteres');
      return; // No envía la solicitud si la contraseña es demasiado corta
    }

    try {
      const response = await api.post(
        '/auth/cambio-clave',
        { password, passwordConfirm },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Enviar el token desde Redux en la cabecera
          },
        }
      );

      setAlert({ type: 'success', message: response.data.message });

      // Mostrar mensaje de éxito
      setAlert({
        type: 'success',
        message: 'Cambio de contraseña exitoso. Tu sesión se cerrará automáticamente.',
      });

      // Destruir la sesión actual y redirigir al inicio de sesión
      setTimeout(() => {
        dispatch(logout());
        navigate('/login');
      }, 3000); // Esperar 3 segundos antes de redirigir
    } catch (error) {
      console.log(error);
      setAlert({
        type: 'danger',
        message: error.response?.data?.error || 'Error al cambiar la contraseña',
      });
    }
  };

  return (
    <div className="d-flex justify-content-center mt-5">
      <Card style={{ width: '100%', maxWidth: '500px', padding: '20px' }}>
        <Card.Body>
          {alert && (
            <Alert variant={alert.type} onClose={() => setAlert(null)} dismissible>
              {alert.message}
            </Alert>
          )}

          {/* Instrucciones claras sobre el cambio de contraseña */}
          <p style={{ fontSize: '18px', fontWeight: '500', color: '#333' }}>
            ¡Hola! Es necesario que cambies tu contraseña, ya que es la primera vez que inicias sesión.
          </p>

          <Form noValidate validated={validated} onSubmit={handleChangePassword}>
            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label>Contraseña Nueva</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="password"
                  placeholder="Ingresa tu nueva contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  isInvalid={password.length < 6} // Validación de longitud mínima
                />
                <Form.Control.Feedback type="invalid">
                  La contraseña debe tener al menos 6 caracteres.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="formPasswordConfirm" className="mb-3">
              <Form.Label>Confirmar Contraseña</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="password"
                  placeholder="Confirma tu nueva contraseña"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  required
                  isInvalid={password !== passwordConfirm} // Validación de coincidencia
                />
                <Form.Control.Feedback type="invalid">
                  Las contraseñas no coinciden.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            {/* Mostrar el error si las contraseñas no coinciden */}
            {error && <Alert variant="danger">{error}</Alert>}

            <Button
              type="submit"
              variant="primary"
              style={{ width: '100%', padding: '10px', fontSize: '16px' }}
            >
              Cambiar Contraseña
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CambioContrasenaPage;
