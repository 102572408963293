import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';

// Acción para obtener beneficios
export const fetchBeneficios = createAsyncThunk('beneficios/fetchBeneficios', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('/api/beneficios/listar'); // Asegúrate de que esta ruta sea correcta
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Error al obtener beneficios');
  }
});

// Acción para crear un beneficio
export const createBeneficio = createAsyncThunk('beneficios/createBeneficio', async (beneficio, { rejectWithValue }) => {
  try {
    const response = await api.post('/api/beneficios/crear', beneficio);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Error al crear beneficio');
  }
});

// Acción para eliminar un beneficio
export const deleteBeneficio = createAsyncThunk('beneficios/deleteBeneficio', async (id, { rejectWithValue }) => {
  try {
    await api.delete(`/api/beneficios/desactivar/${id}`); // Asegúrate de que esta ruta sea correcta
    return id;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Error al eliminar beneficio');
  }
});

const beneficiosSlice = createSlice({
  name: 'beneficios',
  initialState: {
    beneficios: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Manejo de la acción fetchBeneficios
      .addCase(fetchBeneficios.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBeneficios.fulfilled, (state, action) => {
        state.loading = false;
        state.beneficios = action.payload;
      })
      .addCase(fetchBeneficios.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Manejo de la acción createBeneficio
      .addCase(createBeneficio.fulfilled, (state, action) => {
        state.beneficios.push(action.payload);
      })
      // Manejo de la acción deleteBeneficio
      .addCase(deleteBeneficio.fulfilled, (state, action) => {
        state.beneficios = state.beneficios.filter((beneficio) => beneficio.id !== action.payload);
      })
      .addCase(deleteBeneficio.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default beneficiosSlice.reducer;
