import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa los estilos de Bootstrap
import App from './App';
import reportWebVitals from './reportWebVitals'; // Para medir el rendimiento opcionalmente

// Recupera el tema guardado en el localStorage o usa 'system' por defecto
const savedTheme = localStorage.getItem('theme') || 'system';
document.body.setAttribute('data-theme', savedTheme);

// Obtén el elemento raíz correctamente
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// Renderiza la aplicación dentro del contenedor raíz
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// Si quieres medir el rendimiento en tu app, puedes usar reportWebVitals
reportWebVitals();
