import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <div className="text-center mt-5">
    <h1>404</h1>
    <p>Lo sentimos, la página que buscas no existe.</p>
    <Link to="/">Volver al inicio</Link>
  </div>
);

export default NotFoundPage;
