import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentos, downloadDocumento } from '../redux/documentosSlice';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Document, Page, pdfjs } from 'react-pdf';


// Configurar el worker de PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@4.4.168/build/pdf.worker.min.js`;

const DocumentosPage = () => {
    const dispatch = useDispatch();
    const { documentos, error } = useSelector((state) => state.documentos);
    const [selectedPdf, setSelectedPdf] = useState(null); // PDF seleccionado
    const [showError, setShowError] = useState(false); // Controla la visibilidad de la alerta de error
    const [token, setToken] = useState(localStorage.getItem('token'));
    // Obtener los documentos al cargar la página
    useEffect(() => {
        dispatch(fetchDocumentos());
    }, [dispatch]);

    useEffect(() => {
        // Actualiza el estado del token si cambia en el localStorage
        const storedToken = localStorage.getItem('token');
        if (storedToken !== token) {
            setToken(storedToken);
        }
    }, [token]);

    // Mostrar la alerta de error si hay un error
    useEffect(() => {
        if (error) {
            setShowError(true);
        }
    }, [error]);

    // Función para intentar cargar los documentos nuevamente
    const handleRetry = () => {
        setShowError(false); // Oculta la alerta de error
        dispatch(fetchDocumentos()); // Intenta obtener los documentos de nuevo
    };

    // Función para descargar un documento
    const handleDownload = (id) => {
        dispatch(downloadDocumento(id))
            .unwrap()
            .then((response) => {
                // Si el backend usa res.redirect, simplemente abre la URL en el navegador
                window.location.href = response.url;
            })
            .catch((error) => {
                console.error('Error al descargar documento:', error);
                alert('No se pudo descargar el documento.');
            });
    };
    

    // Función para mostrar un documento PDF
    const handleViewPdf = (url) => {
        setSelectedPdf(url); // Establece el PDF seleccionado
    };

    console.log(localStorage.getItem('token'))
    return (
        <div className="container mt-4">
            <h2 className="mb-4">Documentos</h2>

            {/* Mostrar el error con una alerta visual */}
            {showError && (
                <Alert
                    variant="danger"
                    dismissible
                    onClose={() => setShowError(false)} // Simplemente oculta el mensaje
                >
                    <strong>Error:</strong> {error.message || 'Ha ocurrido un problema al cargar los documentos.'}
                    <div className="mt-2">
                        <Button variant="secondary" onClick={handleRetry}>
                            Intentar nuevamente
                        </Button>

                    </div>
                </Alert>
            )}

            {/* Mostrar mensaje si no hay documentos */}
            {!error && documentos.length === 0 && <p>No hay documentos disponibles.</p>}

            {/* Renderizar documentos en un acordeón */}
            {Array.isArray(documentos) && documentos.length > 0 && (
                <Accordion>
                    {documentos.map((documento, index) => (
                        <Accordion.Item eventKey={index.toString()} key={documento.id}>
                            <Accordion.Header>{documento.titulo || 'Documento sin título'}</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    <strong>Fecha de subida:</strong>{' '}
                                    {new Date(documento.fecha_subida).toLocaleDateString()}
                                </p>
                                <Button
                                    variant="success"
                                    className="me-2"
                                    onClick={() => handleViewPdf(documento.url)}
                                >
                                    Ver PDF
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => handleDownload(documento.id)} // Asegúrate de usar documento.id
                                >
                                    Descargar
                                </Button>


                                {/* Renderizar el PDF si está seleccionado */}
                                {selectedPdf === documento.url && (
                                    <div className="mt-3">
                                        <Document
                                            file={selectedPdf}
                                            onLoadError={(error) => console.error('Error al cargar el PDF:', error)}
                                        >
                                            <Page pageNumber={1} />
                                        </Document>
                                    </div>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            )}
        </div>
    );
};

export default DocumentosPage;
